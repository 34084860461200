import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
 import Seo from "../components/seo"
import Hero from "../components/modules/hero"
import Quote from "../components/modules/quote"
import FeaturedClients from "../components/modules/featuredClients"
import WorkWithUs from "../components/modules/workWithUs"
import SliderMarkets from "../components/modules/sliderMarkets"
import Capabilities from "../components/modules/capabilities"
import Resources from "../components/modules/resources"
import PlanLearn from "../components/modules/planLearn"
import ThreeColumn from "../components/modules/threeColumn"
import Markets from "../components/modules/markets"
import ThreeColumnList from "../components/modules/threeColumnList"
import ProductFeed from "../components/products/productFeed"
import PageMenu from "../components/modules/pageMenu"
import FaqAccordion from "../components/modules/faqAccordion"
import SubNavigation from "../components/modules/subNavigation"
import ContentWithLogos from "../components/modules/contentWithLogos"
import ContentBenefits from "../components/modules/contentBenefits"
import Stats from "../components/modules/stats"
import ProjectGallery from "../components/modules/projectGallery"
import QuoteLarge from "../components/modules/quoteLarge"
import FormRegistration from "../components/modules/formRegistration"

export const query = graphql`
  query($id: String) {
    wpMarket(id: { eq: $id }) {
      id
      title
      content
      seo {
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        title
        twitterDescription
        twitterImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        twitterTitle
      }
      layouts {
        layouts {
          ... on WpMarket_Layouts_Layouts_Hero {
            fieldGroupName
            leadingImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 120
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    layout: CONSTRAINED
                    outputPixelDensities: [1, 2]
                    quality: 80
                  )
                }
              }
              altText
            }
            preHeading
            heading
            subHeading
            headingFontSize
            showMarketSelector
            showVideo
            videoId
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2000, height: 750, placeholder: BLURRED)
                }
              }
            }
            mobileBackgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 500, height: 750, placeholder: BLURRED)
                }
              }
            }
            showProductFeatures
            productFeatures{
              feature
            }
            showButtons
            buttons{
              cta {
                target
                title
                url
              }
            }
            showPartnerLogos
            partnerLogos {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 200
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                      layout: CONSTRAINED
                      outputPixelDensities: [1, 2]
                      quality: 80
                    )
                  }
                }
                altText
              }
            }
          }
          ... on WpMarket_Layouts_Layouts_Quote {
           fieldGroupName
           heading
           sectionHeading
         }
         ... on WpMarket_Layouts_Layouts_FaqAccordion {
            fieldGroupName
            sectionHeading
            sectionImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            faqs {
              ... on WpFAQ {
                id
                title
                acfFAQ {
                  answer
                }
              }
            }
          }

         ... on WpMarket_Layouts_Layouts_FeaturedClients {
           ctaHeading
           fieldGroupName
           clientLogos {
              logo {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                altText
              }
            }
           ctaBackgroundImage {
             localFile {
               childImageSharp {
                 gatsbyImageData
               }
             }
           }
           sectionHeading
           ctaButton {
             target
             title
             url
           }
         }
         ... on WpMarket_Layouts_Layouts_WorkWithUs {
            fieldGroupName
            sectionHeading
            useGlobal
            highlights {
              cta {
                target
                title
                url
              }
              fieldGroupName
              heading
              shortDescription
            }
          }
          ... on WpMarket_Layouts_Layouts_Markets {
            fieldGroupName
          }
          ... on WpMarket_Layouts_Layouts_ThreeColumnList {
             fieldGroupName
             heading
             headingCta {
              target
              title
              url
             }
             shortDescription
             lists {
               listHeading
               listItem {
                 listItemText
               }
             }


             theme

          }
          ... on WpMarket_Layouts_Layouts_LearnPlan {
            fieldGroupName
            sectionHeading
            useGlobal
            highlights {
              heading
              icon {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                altText
              }
              label
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              altText
            }
          }
          ... on WpMarket_Layouts_Layouts_Capabilities {
            fieldGroupName
            sectionHeading
            callToAction {
              target
              title
              url
            }
            tabs {
              heading
              shortDescription
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpMarket_Layouts_Layouts_Resources {
            fieldGroupName
            sectionHeading
            resourceList {
             ... on WpPost {
               id
               title
               uri
               resources {
                 callToActionLabel
                 excerpt
                 type
                 whitepaper
                 featuredImage {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
               }
             }
           }
          }
          ... on WpMarket_Layouts_Layouts_ThreeColumnContent {
            fieldGroupName
            sectionHeading
            column {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              heading
              shortDescription
              link {
                target
                title
                url
              }
            }
          }
          ... on WpMarket_Layouts_Layouts_SliderMarkets {
             fieldGroupName
             heading
             markets {
               ... on WpMarket {
                 id
                 slug
                 title
                 markets {
                   featuredImage {
                     altText
                     localFile {
                       childImageSharp {
                         gatsbyImageData
                       }
                     }
                   }
                 }
               }
             }
           }
           ... on WpMarket_Layouts_Layouts_ProductFeed {
            fieldGroupName
          }
          ... on WpMarket_Layouts_Layouts_SubNavigation {
            fieldGroupName
            theme
            navigationItems {
              link {
                target
                url
                title
              }
            }
          }
          ... on WpMarket_Layouts_Layouts_Stats {
            fieldGroupName
            sectionHeading
            stats {
              footnote
              number
              shortDescription
            }
          }
          ... on WpMarket_Layouts_Layouts_ContentWLogos {
            fieldGroupName
            heading
            listItems {
              listItem
            }
            logos {
              logo {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            shortDescription
          }
          ... on WpMarket_Layouts_Layouts_FormRegistration {
             fieldGroupName
             formHeading
             sectionHeading
           }
          ... on WpMarket_Layouts_Layouts_ContentBenefits {
            fieldGroupName
            sectionHeading
            theme
            headerImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 932, height: 480)
                }
              }
            }
            contentGrid {
              heading
              shortDescription
            }
          }
          ... on WpMarket_Layouts_Layouts_QuoteLarge {
            author
            location
            quote
            fieldGroupName
          }
          ... on WpMarket_Layouts_Layouts_ProjectGallery {
            fieldGroupName
            sectionHeading
            projectGallery {
               ... on WpProject {
                 id
                 title
                 uri
                 acfProjects {
                   featuredImage {
                     altText
                     localFile {
                       childImageSharp {
                         gatsbyImageData
                       }
                     }
                   }
                   gallery {
                     image {
                       altText
                       localFile {
                         childImageSharp {
                           gatsbyImageData
                         }
                       }
                     }
                   }
                    location
                    projectDetails {
                      details
                      sectionHeading
                   }
                 }
                 projectTypes {
                   nodes {
                     name
                     slug
                   }
                 }
               }
             }
          }
        }
      }
    }
  }
`

const WpMarket = ({  data: {
    wpMarket: { layouts, seo },
  }}) => {
  return (
    <Layout>

      {seo
        &&
        <Seo title={seo.title} description={seo.metaDesc} seo={seo} />
      }

      {layouts.layouts && layouts.layouts.map(( layout, index ) => {
        if(layout.fieldGroupName === "Market_Layouts_Layouts_Hero") {
          return ( <Hero content={layout} index={index} key={index} /> )
        } else if (layout.fieldGroupName === "Market_Layouts_Layouts_Quote") {
          return ( <Quote content={layout} index={index} key={index} /> )
        } else if (layout.fieldGroupName === "Market_Layouts_Layouts_FeaturedClients") {
          return ( <FeaturedClients content={layout} index={index} key={index} /> )
        } else if (layout.fieldGroupName === "Market_Layouts_Layouts_WorkWithUs") {
          return ( <WorkWithUs content={layout} index={index} key={index} /> )
        } else if (layout.fieldGroupName === "Market_Layouts_Layouts_SliderMarkets") {
          return ( <SliderMarkets content={layout} index={index} key={index} /> )
        } else if (layout.fieldGroupName === "Market_Layouts_Layouts_Capabilities") {
          return ( <Capabilities content={layout} index={index} key={index} /> )
        } else if (layout.fieldGroupName === "Market_Layouts_Layouts_Resources") {
          return ( <Resources content={layout} index={index} key={index} /> )
        } else if (layout.fieldGroupName === "Market_Layouts_Layouts_LearnPlan") {
          return ( <PlanLearn content={layout} index={index} key={index} /> )
        } else if (layout.fieldGroupName === "Market_Layouts_Layouts_ThreeColumnContent") {
          return ( <ThreeColumn content={layout} index={index} key={index} /> )
        } else if (layout.fieldGroupName === "Market_Layouts_Layouts_Markets") {
          return ( <Markets index={index} key={index} /> )
        } else if (layout.fieldGroupName === "Market_Layouts_Layouts_ThreeColumnList") {
          return ( <ThreeColumnList content={layout} index={index} key={index} /> )
        }else if (layout.fieldGroupName === "Market_Layouts_Layouts_ProductFeed") {
          return ( <ProductFeed index={index} key={index} /> )
        }else if (layout.fieldGroupName === "Market_Layouts_Layouts_PageMenu") {
          return ( <PageMenu content={layout} index={index} key={index} /> )
        } else if (layout.fieldGroupName === "Market_Layouts_Layouts_FaqAccordion") {
          return ( <FaqAccordion content={layout} index={index} key={index} /> )
        } else if (layout.fieldGroupName === "Market_Layouts_Layouts_SubNavigation") {
          return ( <SubNavigation content={layout} index={index} key={index} /> )
        } else if (layout.fieldGroupName === "Market_Layouts_Layouts_ContentWLogos") {
          return ( <ContentWithLogos content={layout} index={index} key={index} /> )
        } else if (layout.fieldGroupName === "Market_Layouts_Layouts_ContentBenefits") {
          return ( <ContentBenefits content={layout} index={index} key={index} /> )
        } else if (layout.fieldGroupName === "Market_Layouts_Layouts_Stats") {
          return ( <Stats content={layout} index={index} key={index} /> )
        } else if (layout.fieldGroupName === "Market_Layouts_Layouts_ProjectGallery") {
          return ( <ProjectGallery content={layout} index={index} key={index} /> )
        } else if (layout.fieldGroupName === "Market_Layouts_Layouts_QuoteLarge") {
          return ( <QuoteLarge content={layout} index={index} key={index} /> )
        } else if (layout.fieldGroupName === "Page_Layouts_Layouts_FormRegistration") {
          return ( <FormRegistration content={layout} index={index} key={index} /> )
        }
      })}
    </Layout>
  )
}

export default WpMarket
